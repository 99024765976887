<div class="progress" *ngIf="existingFile">
    <div
      class="progress-bar progress-bar-striped bg-danger"
      attr.aria-valuenow="{{ progress }}"
      aria-valuemin="0"
      aria-valuemax="100"
      [ngStyle]="{ width: progress + '%' }"
    >
      {{ progress }}
    </div>
  </div>
  
  <label class="btn btn-default">
    <input type="file" (change)="chooseFile($event)" accept="video/mp4,video/x-m4v,video/*"/>
  </label>
  
  <button class="btn btn-primary" [disabled]="!chosenFiles" (click)="upload()">
    Upload
  </button>
  
  <div class="alert alert-light">{{ msg }}</div>
  <br/>
  <div class="card">
    <div class="card-header" *ngIf="FileDetail">Videos</div>
    <ul
      class="list-group list-group-flush"
      *ngFor="let file of FileDetail | async"
    >
      <li class="list-group-item">
        <a href="{{ file.url }}">{{ file.name }}</a>
        <ul>
          <li><button class="btn btn-primary" (click)="loadVideo(file.url)">play video</button></li>
          <li><button class="btn btn-primary" (click)="confirmDialog(file.url)">delete</button></li>
        </ul>
      </li>
    </ul> 

    <br/>

    <div class="card-header" *ngIf="vimeo_iframe_html">Video player </div>


    <div id="player_container" class="custom-video-frame" [innerHtml]="vimeo_iframe_html"></div>
  </div>