import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class UploadService {
  private endpoint = 'https://vimeouploadapi.rodrigue.xyz/api';
  constructor(private httpClient: HttpClient) { }

  uploadFile(file: File): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();
    formData.append('file', file);

    const request = new HttpRequest('POST', `${this.endpoint}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.httpClient.request(request);
  }

  getFiles(): Observable<any> {
    return this.httpClient.get(`${this.endpoint}/getvideos`);
  }

  deleteFile(vimeo_url: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {

      },
    };
    const request = new HttpRequest('DELETE', `${this.endpoint}/deletevideo`, {
      video_url: vimeo_url
    }, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.httpClient.request(request);
  }

}
