import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadService } from '../upload.service';
import { EmbedVideoService } from 'ngx-embed-video';
import Player from '@vimeo/player';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadComponent implements OnInit {
  vimeo_iframe_html: any;
  chosenFiles: FileList;
  existingFile: File;
  progress = "";
  msg = "";
  private player: Player;
  private playerContainer;


  FileDetail: Observable<any>;

  constructor(private uploadService: UploadService, private embedService: EmbedVideoService) { }

  ngOnInit(): void {
    this.FileDetail = this.uploadService.getFiles();
  }

  initPlayer() {
    this.playerContainer = document.querySelector('#player_container iframe')
    console.log(this.playerContainer)
    /* DOM AVAILABLE */
    this.player = new Player(this.playerContainer);

    this.player.on('play', function() {
        console.log('played the video!');
    });

    this.player.getVideoTitle().then(function(title) {
        console.log('title:', title);
    });
  }

  chooseFile(event:any): void {
    this.chosenFiles = event.target.files;
  }

  upload(): void {
    this.progress = "";
      this.existingFile = this.chosenFiles.item(0);


    this.uploadService.uploadFile(this.existingFile).subscribe( (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = "In progress";
        } else if (event instanceof HttpResponse) {
          this.msg = event.body.message;
          this.FileDetail = this.uploadService.getFiles();
          this.progress = "Completed";

          this.chosenFiles = undefined;
        }
      }, (error) => {
        this.progress = "";
        this.msg = 'Error occured while uploading file';
        this.existingFile = undefined;
      });

    this.chosenFiles = undefined;
  }

  loadVideo(vimeo_url: string):void {
    this.vimeo_iframe_html = this.embedService.embed(vimeo_url)
    this.initPlayer()
  }

  confirmDialog(vimeo_url: string):void {
    if(confirm("Are you sure to delete " + vimeo_url)) {
      this.delete(vimeo_url);
    }
  }

  delete(vimeo_url: string):void {
    this.uploadService.deleteFile(vimeo_url).subscribe((event) => {
      if (event instanceof HttpResponse) {
        this.msg = event.body.message;
        this.FileDetail = this.uploadService.getFiles();
        this.chosenFiles = undefined;
        this.vimeo_iframe_html = undefined;
      }
    }, (error) => {
      this.progress = "";
      this.msg = 'Error occured while deleting file';
      this.existingFile = undefined;
    });
  }

}